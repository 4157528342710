import React from 'react';
import { useColorScheme, Platform } from 'react-native';
import { Icon } from '@ui-kitten/components';

export default function CustomIcon(props) {
  const colorScheme = useColorScheme();
  const iconColor = colorScheme === 'dark' ? 'white' : 'black';
  return Platform.OS === 'web' ? (
    <Icon color="" fill={iconColor} {...props} onClick={props.onPress} name={props.name} />
  ) : (
    <Icon color="" fill={iconColor} {...props} onPress={props.onPress} name={props.name} />
  );
}
