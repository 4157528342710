import React from 'react';
import {
  useTheme,
  Icon,
  List,
  ListItem,
  Layout,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';

import TopNav from '../../components/TopNav';
import Avatar from '../../components/Avatar';
import { getUsers } from '../../services/Users';
import { getSchedules } from '../../services/Schedules';
import { LOG } from '../../utils/logger';

export default function SelectEmployees({ route, navigation }) {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();

  const [users, setUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [unavailableEmployees, setUnavailableEmployees] = React.useState([]);

  const fetchUsers = () => {
    getUsers()
      .then((response) => {
        const data = JSON.parse(response.response);
        const filteredData = data
          .filter((item) => item.status !== 'inactive')
          .sort((a, b) => a.name.localeCompare(b.name));
        setUsers(filteredData);
      })
      .catch((e) => LOG.warn(e));
  };

  const fetchSchedules = () => {
    if (route.params.date) {
      let startTime = new Date(route.params.date).setHours(0, 0, 0, 0);
      let endTime = new Date(route.params.date).setHours(23, 59, 59, 999);

      getSchedules(startTime, endTime)
        .then((res) => {
          var docs = res.documents;
          setUnavailableEmployees(docs.map((item) => item.userId));
        })
        .catch((e) => LOG.warn(e));
    }
  };

  React.useEffect(() => {
    fetchUsers();
    if (route.params) {
      setSelectedUsers(route.params.selectedUsers);
      fetchSchedules();
    }
  }, [route.params]);

  const renderAvatar = (user) => {
    return <Avatar name={user.name} userId={user.userId} />;
  };

  const getAvailableEmployees = () => {
    return users.filter((user) => !unavailableEmployees.includes(user.$id));
  };

  const selectUser = (user) => {
    var users = [...selectedUsers];
    if (users.map((u) => u.$id).includes(user.$id)) {
      users = users.filter((u) => u.$id !== user.$id);
    } else {
      users.push(user);
    }
    setSelectedUsers(users);
  };

  const isSelected = (user) => {
    return selectedUsers.map((u) => u.userId).includes(user.userId);
  };

  const renderRadio = (user) => {
    if (isSelected(user)) {
      return <Icon color="" name="checkmark-circle-outline" fill={theme['color-primary-default']} />;
    }
    return <Icon color="" name="radio-button-off-outline" />;
  };

  const onSave = () => {
    navigation.navigate('AddSchedule', { selectedUsers: selectedUsers });
  };

  const renderItem = ({ item }) => (
    <ListItem
      title={item.userName}
      style={
        isSelected(item) ? { borderWidth: 1, borderColor: theme['color-primary-default'] } : {}
      }
      description={item.userEmail}
      accessoryLeft={renderAvatar(item)}
      accessoryRight={renderRadio(item)}
      onPress={() => selectUser(item)}
    />
  );

  return (
    <Layout style={styles.container}>
      <TopNav
        title="Select Employees"
        leftAction={'back'}
        rightAction={{ icon: 'save-outline', action: onSave }}
      />

      <List style={styles.listContainer} data={getAvailableEmployees()} renderItem={renderItem} />
    </Layout>
  );
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
});
