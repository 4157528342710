import React from 'react';
import { View } from 'react-native';
import { Layout, StyleService, useStyleSheet, TopNavigationAction } from '@ui-kitten/components';

import AlertModal from '../../components/AlertModal';
import Loading from '../../components/Loading';
import TopNav from '../../components/TopNav';
import CustomIcon from '../../components/CustomIcon';
import InputEmployee from '../../components/InputEmployee';
import InputDate from '../../components/InputDate';
import InputTime from '../../components/InputTime';
import InputLocation from '../../components/InputLocation';
import InputStatus from '../../components/InputStatus';
import InputNote from '../../components/InputNote';
import { LOG } from '../../utils/logger';
import { isAdmin } from '../../services/Auth';
import { getSchedule, updateSchedule, deleteSchedule } from '../../services/Schedules';

export default function EditSchedule({ route, navigation }) {
  const styles = useStyleSheet(themedStyles);
  const [admin, setAdmin] = React.useState(false);
  const [schedule, setSchedule] = React.useState();
  const [employeeId, setEmployeeId] = React.useState();
  const [locationId, setLocationId] = React.useState();
  const [scheduleDate, setScheduleDate] = React.useState();
  const [startTime, setStartTime] = React.useState();
  const [endTime, setEndTime] = React.useState();
  const [note, setNote] = React.useState();
  const [status, setStatus] = React.useState();
  const [alertVisible, setAlertVisible] = React.useState(false);

  React.useEffect(() => {
    isAdmin().then((res) => {
      setAdmin(res);
    });
    getSchedule(route.params.scheduleId).then((res) => {
      setSchedule(res);
      setScheduleDate(new Date(res.startTime));
      setEmployeeId(res.userId);
      setLocationId(res.locationId);
      setStartTime(res.startTime);
      setEndTime(res.endTime);
      setNote(res.note);
      setStatus(res.status);
    });
  }, [route.params.scheduleId]);

  const onSave = () => {
    const updatedSchedule = {
      userId: employeeId,
      // for debugging time purpose
      // startTime: new Date(updateTime(scheduleDate, startTime)).toLocaleString(),
      // endTime: new Date(updateTime(scheduleDate, endTime)).toLocaleString(),
      startTime: updateTime(scheduleDate, startTime),
      endTime: updateTime(scheduleDate, endTime),
      locationId,
      note,
    };
    if (status) {
      updatedSchedule.status = status;
    }
    updateSchedule(route.params.scheduleId, updatedSchedule).catch((e) => LOG.warn(e));
    navigation.goBack();
  };

  const setEmployee = (e) => {
    const employee = e[0];
    setEmployeeId(employee.$id);
  };

  const setDate = (date) => {
    scheduleDate.setFullYear(date.getFullYear());
    scheduleDate.setMonth(date.getMonth());
    scheduleDate.setDate(date.getDate());
    setScheduleDate(scheduleDate);
  };

  const updateTime = (date, time) => {
    const tm = new Date(time);
    const dt = new Date(date);
    dt.setHours(tm.getHours(), tm.getMinutes(), 0, 0);
    return dt.getTime();
  };

  const setTime = (start, end) => {
    setStartTime(start);
    setEndTime(end);
  };

  const setLocation = (location) => {
    setLocationId(location.$id);
  };

  const renderRightAction = () => {
    if (admin) {
      return (
        <View style={styles.rightAction}>
          <TopNavigationAction
            icon={<CustomIcon name="trash-outline" onPress={() => setAlertVisible(true)} />}
          />
          <TopNavigationAction icon={<CustomIcon name="save-outline" onPress={onSave} />} />
        </View>
      );
    }
  };

  return (
    <Layout style={styles.container}>
      <TopNav title="Edit Schedule" leftAction={'back'} rightAction={renderRightAction()} />
      {schedule && employeeId ? (
        <>
          <InputEmployee employeeId={employeeId} onPress={isAdmin ? setEmployee : null} />
          <InputDate date={scheduleDate} onPress={setDate} />
          <InputTime onPress={setTime} startTime={startTime} endTime={endTime} />
          <InputLocation locationId={locationId} onPress={setLocation} />
          <InputStatus status={status} onPress={setStatus} />
          <InputNote value={note} setValue={setNote} />
        </>
      ) : (
        <Loading />
      )}
      <AlertModal
        visible={alertVisible}
        setVisible={setAlertVisible}
        title="Delete Schedule?"
        onAccept={() => {
          deleteSchedule(route.params.scheduleId);
          navigation.goBack();
        }}
        acceptText="Delete"
      />
    </Layout>
  );
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  rightAction: {
    flexDirection: 'row',
  },
});
