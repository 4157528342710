import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NavigationContainer } from '@react-navigation/native';
import { Layout } from '@ui-kitten/components';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';

import MainNav from './MainNav';
import Login from '../screens/Login';
import Forgot from '../screens/Forgot';
import ChangePwd from '../screens/ChangePwd';
import Splash from '../screens/Splash';

const Stack = createNativeStackNavigator();

const linking = {
  prefixes: ['https://eggies.app', 'eggies://'],
  config: {
    screens: {
      Login: 'login',
      Forgot: 'forgot',
      ChangePwd: 'change-password',
      Main: {
        initialRouteName: 'home',
        screens: {
          Home: 'home',
          Schedule: {
            path: 'schedules',
            screens: {
              Schedules: 'list',
              AddSchedule: 'add',
            },
          },
          UserNav: {
            path: 'users',
            screens: {
              Users: 'list',
              AddUser: 'add',
              ViewUser: 'view',
            },
          },
        },
      },
    },
  },
};

export default function RootNav() {
  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaProvider>
        <NavigationContainer linking={linking}>
          <SafeAreaView style={{ flex: 1 }}>
            <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName="Splash">
              <Stack.Screen name="Splash" component={Splash} />
              <Stack.Screen name="Main" component={MainNav} />
              <Stack.Screen name="Login" component={Login} />
              <Stack.Screen name="Forgot" component={Forgot} />
              <Stack.Screen name="ChangePwd" component={ChangePwd} />
            </Stack.Navigator>
          </SafeAreaView>
        </NavigationContainer>
      </SafeAreaProvider>
    </Layout>
  );
}
