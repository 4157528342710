import React, { useEffect, useState } from 'react';
import { useColorScheme, StyleSheet } from 'react-native';
import { List, ListItem, Layout, Text } from '@ui-kitten/components';

import { logout } from '../../services/Auth';
import TopNav from '../../components/TopNav';
import VERSIONS from '../../constants/versions';
import { APPWRITE_SERVER } from '../../constants/constants';

export default function Settings({ navigation }) {
  let colorScheme = useColorScheme();
  const [data, setData] = useState([]);

  useEffect(() => {
    const settingData = [];
    settingData.push({ title: 'Color Scheme', value: colorScheme });
    settingData.push({
      title: 'Version',
      value: VERSIONS[0].version,
      onPress: () => {
        navigation.navigate('Versions');
      },
    });
    settingData.push({ title: 'Appwrite Server', value: APPWRITE_SERVER });
    settingData.push({
      title: 'Logs',
      value: '',
      onPress: () => {
        navigation.navigate('Logs');
      },
    });
    setData(settingData);
  }, []);

  const handleLogout = () => {
    logout(navigation);
  };

  const renderItem = ({ item, index }) => (
    <ListItem
      key={index}
      title={item.title}
      accessoryRight={() => <Text>{item.value}</Text>}
      onPress={item.onPress}
    />
  );

  return (
    <Layout style={styles.container} level="1">
      <TopNav
        title="Setting"
        leftAction={'menu'}
        rightAction={{ name: 'logout', action: handleLogout }}
      />
      <List style={styles.listContainer} data={data} renderItem={renderItem} />
    </Layout>
  );
}
const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  button: { marginBottom: 10 },
  listContainer: {},
});
