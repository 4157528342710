const VERSIONS = [
  {
    version: '2.0.0',
    changes: ['update expo 50.0.5', 'update react native to 0.73.2', 'update react to 18.2.0'],
    date: '2024-02-03',
  },
  {
    version: '1.7.1',
    date: '2023-09-24',
    changes: ['Fix crash on android'],
  },
  {
    version: '1.7.0',
    date: '2023-09-8',
    changes: ['Refactor DB to db.eggies.app'],
  },
  {
    version: '1.6.0',
    date: '2023-08-27',
    changes: ['Refactor Get Users'],
  },
  {
    version: '1.5.0',
    date: '2023-06-27',
    changes: ['Add Date on Home screen', 'Add logs screen', 'Add Toast Modal'],
  },
  {
    version: '1.4.0',
    date: '2023-05-22',
    changes: [
      'Add Home Screen',
      'Admin go to edit mode',
      'remove store info on day store',
      'Alert on web',
    ],
  },
  {
    version: '1.3.0',
    date: '2023-05-20',
    changes: ['Added off request'],
  },
  {
    version: '1.2.0',
    date: '2023-04-22',
    changes: ['Added Version History'],
  },
];

export default VERSIONS;
