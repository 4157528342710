import { TouchableOpacity, StyleSheet, Text, View, DeviceEventEmitter } from 'react-native';
import React, { useEffect, useState, useRef } from 'react';

const Toast = () => {
  const colors = {
    info: 'orange',
    success: 'green',
    danger: 'red',
  };
  const [timeout, setTimeout] = useState(4000);
  const timeoutRef = useRef(null);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);

  useEffect(() => {
    DeviceEventEmitter.addListener('SHOW_TOAST', onNewToast);
    return () => {
      DeviceEventEmitter.removeAllListeners();
    };
  }, []);

  const closeToast = () => {
    setMessage(null);
    setTimeout(4000);
    clearInterval(timeoutRef.current);
  };

  useEffect(() => {
    if (message) {
      timeoutRef.current = setInterval(() => {
        if (timeout === 0) closeToast();
        else setTimeout((prev) => prev - 1000);
      }, 1000);
    }
    return () => {
      clearInterval(timeoutRef.current);
    };
  }, [message, timeout]);

  const onNewToast = (data) => {
    if (data.duration) setTimeout(data.duration);
    setMessage(data.message);
    setMessageType(data.type);
  };
  if (!message) return null;
  return (
    <View style={styles.toastContainer}>
      <TouchableOpacity style={{ backgroundColor: colors[messageType] }}>
        <Text style={styles.textStyle}>{message}</Text>
      </TouchableOpacity>
    </View>
  );
};
const styles = StyleSheet.create({
  toastContainer: {
    position: 'absolute',
    bottom: '4%',
    left: '4%',
    right: '4%',
    zIndex: 1,
    elevation: 1,
  },
  textStyle: {
    padding: 14,
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'white',
  },
});
export default Toast;
