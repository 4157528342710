import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Layout, List, ListItem } from '@ui-kitten/components';
import { useIsFocused } from '@react-navigation/native';

import TopNav from '../../components/TopNav';
import Avatar from '../../components/Avatar';
import DotMenu from '../../components/DotMenu';

import { getActiveUsers, getInactiveUsers } from '../../services/Users';
import { isAdmin } from '../../services/Auth';
import { LOG } from '../../utils/logger';

export default function ListUsers({ navigation }) {
  const [users, setUsers] = useState([]);
  const [active, setActive] = useState(true);
  const isFocused = useIsFocused();
  const [title, setTitle] = useState('Active Users');
  const [userMenu, setUserMenu] = useState('Inactive Users');

  const fetchUsers = (status) => {
    setActive(status);
    if (status) {
      getActiveUsers()
        .then((data) => {
          setUsers(data);
          setTitle('Active Users');
          setUserMenu('Inactive Users');
        })
        .catch((e) => LOG.warn(e));
    } else {
      getInactiveUsers()
        .then((data) => {
          setUsers(data);
          setTitle('Inactive Users');
          setUserMenu('Active Users');
        })
        .catch((e) => LOG.warn(e));
    }
  };

  useEffect(() => {
    isAdmin().then((response) => {
      if (!response) {
        navigation.replace('Home');
      }
    });

    if (isFocused) {
      fetchUsers(active);
    }
  }, [isFocused]);

  const viewUser = (user) => {
    navigation.navigate('ViewUser', { user: user });
  };

  const renderAvatar = (user) => {
    return <Avatar name={user.name} userId={user.$id} />;
  };

  const renderItem = ({ item }) => (
    <ListItem
      title={item.name}
      description={item.email}
      accessoryLeft={renderAvatar(item)}
      onPress={() => viewUser(item)}
    />
  );

  const actions = [
    { title: 'Add User', onSelect: () => navigation.navigate('AddUser') },
    {
      title: userMenu,
      onSelect: () => {
        fetchUsers(!active);
      },
    },
  ];

  return (
    <Layout style={styles.container}>
      <TopNav title={title} leftAction={'menu'} rightAction={<DotMenu actions={actions} />} />
      <List style={styles.listContainer} data={users} renderItem={renderItem} />
    </Layout>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listContainer: {},
});
