import React from 'react';
import { StyleService, useStyleSheet, Text, Modal, Layout, Button } from '@ui-kitten/components';
import { View, Dimensions } from 'react-native';

// Alert Modal
// This modal is used to display alerts to the user
// Props:
// visible: boolean - whether the modal is visible
// setVisible: function - function to set the visibility of the modal
// title: string - title of the modal
// onDeny: function - function to run when the user denies the alert
// onAccept:  function - function to run when the user accepts the alert
// denyText: string - text to display on the deny button
// acceptText: string - text to display on the accept button

export default function AlertModal(props) {
  const styles = useStyleSheet(themedStyles);
  const width = Dimensions.get('window').width;
  const height = Dimensions.get('window').height;

  const getModalWidth = () => {
    return width > 500 ? 500 : width - 20;
  };

  const onDeny = () => {
    if (props.onDeny) {
      props.onDeny();
    }
    props.setVisible(false);
  };

  const onAccept = () => {
    if (props.onAccept) {
      props.onAccept();
    }
    props.setVisible(false);
  };

  return (
    <Modal
      visible={props.visible}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => props.setVisible(false)}
    >
      <Layout style={[styles.layout, { width: getModalWidth(), maxHeight: height - 100 }]}>
        <Text style={styles.title}>{props.title}</Text>
        <View style={styles.buttonGroup}>
          <Button status="danger" style={styles.button} onPress={onDeny}>
            {props.denyText ? props.denyText : 'Cancel'}
          </Button>
          <Button status="success" style={styles.button} onPress={onAccept}>
            {props.acceptText ? props.acceptText : 'OK'}
          </Button>
        </View>
      </Layout>
    </Modal>
  );
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  layout: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: 20,
    padding: 20,
  },
  button: {
    margin: 10,
    width: '40%',
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    margin: 10,
  },
});
