// Input for Employee
import React from 'react';

import InputBox from './InputBox';
import AlertModal from './AlertModal';

export default function InputStatus(props) {
  const [modalVisible, setModalVisible] = React.useState(false);

  const onPress = () => {
    setModalVisible(true);
  };

  const onDeny = () => {
    props.onPress('denied');
  };

  const onApprove = () => {
    props.onPress('approved');
  };

  return (
    <>
      <InputBox
        title="Status"
        content={props.status ? props.status : 'Select a Status'}
        icon="loader-outline"
        color="aquamarine"
        onPress={props.onPress ? onPress : null}
      />
      <AlertModal
        visible={modalVisible}
        setVisible={setModalVisible}
        title="Approve Requests?"
        onDeny={onDeny}
        denyText={'Deny'}
        onAccept={onApprove}
        acceptText={'Approve'}
      />
    </>
  );
}
