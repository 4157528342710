import { Databases, Query } from 'appwrite';

import client from '../utils/appwrite';

const db = new Databases(client);

const DATABASE_ID = 'scheduling';
const LOCATIONS_COLLECTION_ID = 'locations';

// get locations
// returns a promise
// resolves to an array of locations
// rejects to an error
const getLocations = () => {
  return db.listDocuments(DATABASE_ID, LOCATIONS_COLLECTION_ID, [Query.orderAsc('order')]);
};

// get location
// returns a promise
// resolves to a location
// rejects to an error
const getLocation = (locationId) => {
  return db.getDocument(DATABASE_ID, LOCATIONS_COLLECTION_ID, locationId);
};

// get location by name
// returns a promise
// resolves to a locationId
const getLocationId = (locationName) => {
  return db
    .listDocuments(DATABASE_ID, LOCATIONS_COLLECTION_ID, [Query.equal('name', [locationName])])
    .then((res) => {
      if (res.documents.length > 0) {
        return res.documents[0].$id;
      }
      return null;
    });
};

export { getLocations, getLocation, getLocationId };
