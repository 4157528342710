import React from 'react';
import { OverflowMenu, MenuItem, TopNavigationAction } from '@ui-kitten/components';

import CustomIcon from './CustomIcon';

// Overflow menu on the right side of the top navigation bar
// Props:
//  icon [optional]: icon to display (default to more-vertical)
//  actions: array of actions to display in the menu
//  [{
//     title: title of the menu,
//     onSelect: function to call when an action is selected
//  }]

export default function DotMenu(props) {
  const [visible, setVisible] = React.useState(false);

  const onMenuSelect = (index) => {
    const row = index.row;
    props.actions[row].onSelect();
    setVisible(false);
  };

  return (
    <OverflowMenu
      anchor={() => (
        <TopNavigationAction
          icon={
            <CustomIcon
              name={props.icon ? props.icon : 'more-vertical'}
              onPress={() => setVisible(true)}
            />
          }
        />
      )}
      visible={visible}
      onSelect={onMenuSelect}
      onBackdropPress={() => setVisible(false)}
    >
      {props.actions.map((action, index) => (
        <MenuItem key={index} title={action.title} />
      ))}
    </OverflowMenu>
  );
}
