// month picker component

import React from 'react';
import { TouchableOpacity, StyleSheet, View, useColorScheme } from 'react-native';
import { Popover, Text, Calendar, NativeDateService } from '@ui-kitten/components';

import CustomIcon from './CustomIcon';

export default function MonthPicker(props) {
  const [visible, setVisible] = React.useState(false);
  const [currentDate, setCurrentDate] = React.useState(props.currentDate);

  const localeDateService = new NativeDateService('en', { startDayOfWeek: 1 });

  const colorScheme = useColorScheme();

  React.useEffect(() => {
    setCurrentDate(props.currentDate);
  }, [props.currentDate]);

  const monthPicker = () => {
    setVisible(true);
  };

  const renderTouchable = () => (
    <TouchableOpacity onPress={monthPicker} style={styles.container}>
      <Text style={styles.text}>
        {new Date(currentDate).toLocaleString('default', { month: 'long' })}
      </Text>
      <CustomIcon
        fill={colorScheme === 'dark' ? 'white' : 'black'}
        style={styles.icon}
        name="arrow-down"
      />
    </TouchableOpacity>
  );

  const getMaxDate = () => {
    const curr = new Date(currentDate);
    const max = curr.setFullYear(curr.getFullYear() + 1);
    return new Date(max);
  };

  const pickDate = (date) => {
    setVisible(false);
    props.setCurrentDate(date);
  };

  return (
    <View>
      <Popover visible={visible} anchor={renderTouchable} onBackdropPress={() => setVisible(false)}>
        <Calendar
          dateService={localeDateService}
          date={new Date(currentDate)}
          boundingMonth={true}
          max={getMaxDate()}
          startDayOfWeek="Monday"
          onSelect={(nextDate) => pickDate(nextDate)}
        />
      </Popover>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingVertical: 5,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  calendarContainer: {
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
  },
  icon: {
    width: 20,
    height: 20,
    paddingHorizontal: 10,
  },
});
