import React from 'react';
import LottieView from 'lottie-react-native';
import { StyleSheet } from 'react-native';

export default function Loading() {
  const animation = React.useRef(null);
  return (
    <LottieView
      autoPlay
      ref={animation}
      style={styles.egg}
      source={require('../assets/bounce-egg.json')}
    />
  );
}
const styles = StyleSheet.create({
  egg: {
    width: 350,
    height: 350,
  },
});
