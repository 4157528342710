import React from 'react';

import {
  Layout,
  TopNavigationAction,
  Menu,
  MenuItem,
  MenuGroup,
  Button,
  Modal,
  useStyleSheet,
  StyleService,
} from '@ui-kitten/components';
import { View } from 'react-native';

import CustomIcon from './CustomIcon';
import { getLocations } from '../services/Locations';
import { getActiveUsers } from '../services/Users';

export default function Filter(props) {
  const styles = useStyleSheet(themedStyles);
  const [visible, setVisible] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const [locations, setLocations] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);

  const fetchData = () => {
    getLocations().then((res) => {
      setLocations(res.documents);
    });
    getActiveUsers().then((data) => {
      setEmployees(data);
    });
  };

  const renderIcon = () => {
    return (
      <TopNavigationAction
        icon={
          <CustomIcon
            name="funnel-outline"
            onPress={() => {
              fetchData();
              setVisible(true);
            }}
          />
        }
      />
    );
  };

  const renderButton = () => {
    return (
      <View style={styles.buttonGroup}>
        <Button appearance="ghost" status="danger" onPress={() => setVisible(false)}>
          Cancel
        </Button>
        <Button appearance="ghost" status="primary" onPress={() => setVisible(false)}>
          Filter Schedules
        </Button>
      </View>
    );
  };

  const onFilter = (f) => {
    setVisible(false);
    props.onFilter(f);
  };

  return (
    <>
      {renderIcon()}
      <Modal
        style={styles.modal}
        backdropStyle={styles.backdrop}
        visible={visible}
        onBackdropPress={() => setVisible(false)}
      >
        <Layout style={styles.container}>
          {renderButton()}
          <Menu selectedIndex={selectedIndex} onSelect={(index) => setSelectedIndex(index)}>
            <MenuItem title="Show All" onPress={() => onFilter({ type: 'all' })} />
            <MenuGroup title="Filter by locations">
              {locations.map((location) => (
                <MenuItem
                  key={location.$id}
                  title={location.name}
                  onPress={() =>
                    onFilter({ type: 'location', id: location.$id, name: location.name })
                  }
                />
              ))}
            </MenuGroup>
            <MenuGroup title="Filter by employees">
              {employees.map((employee) => (
                <MenuItem
                  key={employee.$id}
                  title={employee.name}
                  onPress={() => onFilter({ type: 'user', id: employee.$id, name: employee.name })}
                />
              ))}
            </MenuGroup>
          </Menu>
        </Layout>
      </Modal>
    </>
  );
}
const themedStyles = StyleService.create({
  modal: {
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    minWidth: '90%',
    padding: 10,
    maxHeight: '90%',
    marginVertical: 20,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  buttonGroup: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
  },
});
