import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { Icon, Text, Input } from '@ui-kitten/components';

// Props
// value: string
//  The content of the input
// setValue: function
//  The function to call when the input is pressed
export default function InputNote(props) {
  const color = 'orange';
  return (
    <TouchableOpacity
      style={styles.container}
      disabled={!props.onPress || props.onPress === null}
      onPress={props.onPress}
    >
      <Icon color="" style={styles.icon} fill={color} name={'edit-2'} />
      <View style={styles.inputContainer}>
        <Text style={[styles.inputLabel, { color: color }]}>Note</Text>
        {props.note ? (
          <Text style={styles.input}>{props.note}</Text>
        ) : (
          <Input
            placeholder="Enter Note..."
            value={props.value}
            onChangeText={(nextValue) => props.setValue(nextValue)}
          />
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    flexDirection: 'row',
    maxHeight: 75,
    alignItems: 'center',
  },
  icon: {
    width: 32,
    height: 32,
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'column',
    marginHorizontal: 10,
    paddingBottom: 5,
    borderBottomColor: 'gray',
    borderBottomWidth: 1,
    justifyContent: 'space-between',
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
});
