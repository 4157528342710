import React from 'react';
import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';

import { LOG } from '../../utils/logger';
import toast from '../../utils/toast';
import { getLoggedInUser } from '../../services/Auth';
import { addSchedule } from '../../services/Schedules';
import { getLocationId } from '../../services/Locations';
import { sendText } from '../../services/Messages';

import TopNav from '../../components/TopNav';
import Loading from '../../components/Loading';
import InputEmployee from '../../components/InputEmployee';
import InputDate from '../../components/InputDate';
import InputTime from '../../components/InputTime';
import InputNote from '../../components/InputNote';

export default function OffRequest({ navigation }) {
  const styles = useStyleSheet(themedStyles);
  const [user, setUser] = React.useState();

  const [range, setRange] = React.useState({ startDate: new Date() });
  const [startTime, setStartTime] = React.useState(new Date().setHours(8, 0, 0, 0));
  const [endTime, setEndTime] = React.useState(new Date().setHours(17, 0, 0, 0));

  const [note, setNote] = React.useState('');
  const [offLocationId, setOffLocationId] = React.useState();

  React.useEffect(() => {
    getLoggedInUser().then((u) => {
      setUser(u);
    });
    // get location Id of 'Off' location
    getLocationId('Off').then((locationId) => {
      setOffLocationId(locationId);
    });
  }, []);

  const setTime = (start, end) => {
    setStartTime(start);
    setEndTime(end);
  };

  const onSave = () => {
    const startDate = new Date(range.startDate);
    startDate.setHours(0, 0, 0, 0);
    // if there is no endDate then endDate = startDate
    const endDate = range.endDate ? new Date(range.endDate) : new Date(range.startDate);
    endDate.setHours(23, 59, 59, 999);
    let currentDate = startDate;
    while (currentDate <= endDate) {
      const dateStartTime = new Date(startTime);
      const dateEndTime = new Date(endTime);
      const scheduleStartTime = new Date(currentDate).setHours(
        dateStartTime.getHours(),
        dateStartTime.getMinutes(),
        0,
        0
      );
      const scheduleEndTime = new Date(currentDate).setHours(
        dateEndTime.getHours(),
        dateEndTime.getMinutes(),
        0,
        0
      );
      LOG.debug({
        userId: user.$id,
        startTime: new Date(scheduleStartTime).toLocaleString(),
        endTime: new Date(scheduleEndTime).toLocaleString(),
        locationId: offLocationId,
        status: 'pending',
        note,
      });
      addSchedule({
        userId: user.userId,
        startTime: scheduleStartTime,
        endTime: scheduleEndTime,
        locationId: offLocationId,
        status: 'pending',
        note,
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    // send text to manager
    sendText({ message: offMessage(), to: '+12158695348' });
    toast.success({ message: 'Off Request submitted' });
    navigation.navigate('Schedules');
  };

  // construct off message
  const offMessage = () => {
    if (range.endDate) {
      return `${user.userName} request off from ${new Date(
        range.startDate
      ).toLocaleDateString()} to ${new Date(range.endDate).toLocaleDateString()}`;
    }
    return `${user.userName} request off on ${new Date(range.startDate).toLocaleDateString()}`;
  };

  return (
    <Layout style={styles.container}>
      <TopNav
        title="Off Request"
        leftAction={'back'}
        rightAction={{ icon: 'save-outline', action: onSave }}
      />
      {user === undefined ? (
        <Loading />
      ) : (
        <>
          <InputEmployee employeeId={user.userId} />
          <InputDate range onPress={setRange} />
          <InputTime onPress={setTime} startTime={startTime} endTime={endTime} />
          <InputNote value={note} setValue={setNote} />
        </>
      )}
    </Layout>
  );
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
});
