import React from 'react';
import { Layout, StyleService, Text, useStyleSheet, Divider } from '@ui-kitten/components';

import TopNav from '../../components/TopNav';
import VERSIONS from '../../constants/versions';

export default function Versions() {
  const styles = useStyleSheet(themedStyles);

  return (
    <Layout style={styles.container}>
      <TopNav title="Versions" leftAction={'back'} />
      <Layout style={styles.inner}>
        {VERSIONS.map((v, i) => (
          <Layout key={i} style={styles.version}>
            <Text category="h6">Version: {v.version}</Text>
            <Text category="s1">Date: {v.date}</Text>
            {v.changes.map((c, i) => (
              <Text key={i} category="s1">
                - {c}
              </Text>
            ))}
            <Divider style={styles.divider} />
          </Layout>
        ))}
      </Layout>
    </Layout>
  );
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  inner: {
    padding: 10,
  },
  version: {
    marginBottom: 10,
  },
  divider: {
    marginVertical: 10,
  },
});
