const VERSION = '1.1.0';
const LOG_FILE = 'eggies.log';
const APPWRITE = {
  teamId: 'eggcellent',
  adminRole: 'admin',
  memberRole: 'member',
  host: 'https://eggies.app',
};
const APPWRITE_SERVER = "https://db.eggies.app/v1";

export { LOG_FILE, APPWRITE, VERSION, APPWRITE_SERVER };
