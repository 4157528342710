// Input for Employee
import React from 'react';
import { List, ListItem, StyleService, useStyleSheet, Icon, useTheme } from '@ui-kitten/components';

import InputBox from './InputBox';
import InputModal from './InputModal';
import Avatar from './Avatar';
import Loading from './Loading';
import { getActiveUsers, getUserMembership } from '../services/Users';
import { getSchedules } from '../services/Schedules';
import { LOG } from '../utils/logger';

// PROPS
// multiselect: boolean (default: false)
//    - true: multiple employees can be selected
// employeeIds: array of string (default: null)
//   - ids of employee to be selected
// date: Date (default: null)
//  - date to check for unavailable employees
// onPress: function (required)
//  - function to set the selected employees

export default function InputEmployee(props) {
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const [content, setContent] = React.useState('Select Employees');
  const [employees, setEmployees] = React.useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [unavailableEmployees, setUnavailableEmployees] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getUserMembership(props.employeeId).then((res) => {
      if (res.total > 0) {
        const user = res.memberships[0];
        setEmployees([user]);
        setContent(user.userName);
      }
    });
  }, [props.employeeIds]);

  const onPress = () => {
    fetchSchedules();
    fetchUsers();
    if (props.employeeId) {
      setSelectedUsers(employees);
    }
    setModalVisible(true);
  };

  const fetchUsers = () => {
    getActiveUsers()
      .then((data) => {
        setUsers(data);
        setLoading(false);
      })
      .catch((e) => LOG.warn(e));
  };

  const fetchSchedules = () => {
    // if date props provided then check whether it is date object or range object
    // if range object set start time as startdate
    // check if there is no end date then use start date as end date
    // if date object then set start time as date and end time as date
    if (props.date) {
      let startTime;
      let endTime;
      if ('startDate' in props.date) {
        startTime = new Date(props.date.startDate).setHours(0, 0, 0, 0);
        if ('endDate' in props.date) {
          endTime = new Date(props.date.endDate).setHours(23, 59, 59, 999);
        } else {
          endTime = new Date(props.date.startDate).setHours(23, 59, 59, 999);
        }
      } else {
        startTime = new Date(props.date).setHours(0, 0, 0, 0);
        endTime = new Date(props.date).setHours(23, 59, 59, 999);
      }

      getSchedules(startTime, endTime)
        .then((res) => {
          var docs = res.documents;
          setUnavailableEmployees(docs.map((item) => item.userId));
        })
        .catch((e) => LOG.warn(e));
    }
  };

  const renderAvatar = (user) => {
    return <Avatar name={user.name} userId={user.$id} />;
  };

  const getAvailableEmployees = () => {
    return users.filter((user) => !unavailableEmployees.includes(user.$id));
  };

  const selectUser = (user) => {
    var users = [...selectedUsers];
    if (props.multiselect) {
      if (users.map((u) => u.$id).includes(user.$id)) {
        users = users.filter((u) => u.$id !== user.$id);
      } else {
        users.push(user);
      }
    } else {
      users = [user];
    }
    setSelectedUsers(users);
  };

  const isSelected = (user) => {
    return selectedUsers.map((u) => u.$id).includes(user.$id);
  };

  const renderRadio = (user) => {
    if (isSelected(user)) {
      return <Icon color="" name="checkmark-circle-outline" fill={theme['color-primary-default']} />;
    }
    return <Icon color="" name="radio-button-off-outline" />;
  };

  const renderItem = ({ item }) => (
    <ListItem
      title={item.name}
      style={
        isSelected(item) ? { borderWidth: 1, borderColor: theme['color-primary-default'] } : {}
      }
      description={item.email}
      accessoryLeft={renderAvatar(item)}
      accessoryRight={renderRadio(item)}
      onPress={() => selectUser(item)}
    />
  );

  const renderEmployeesPicker = () => {
    return (
      <InputModal onSave={onSave} visible={modalVisible} setVisible={setModalVisible}>
        {loading ? (
          <Loading />
        ) : (
          <List
            style={styles.listContainer}
            data={getAvailableEmployees()}
            renderItem={renderItem}
          />
        )}
      </InputModal>
    );
  };

  const onSave = () => {
    setContent(selectedUsers.map((u) => u.name).join(', '));
    props.onPress(selectedUsers);
    setModalVisible(false);
  };

  return (
    <>
      <InputBox
        title="Employee"
        content={content}
        icon="person-outline"
        color={theme['color-primary-default']}
        onPress={props.onPress ? onPress : null}
      />
      {renderEmployeesPicker()}
    </>
  );
}

const themedStyles = StyleService.create({
  listContainer: {
    width: '100%',
  },
});
