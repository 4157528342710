import React, { useEffect, useState } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { Drawer, DrawerItem, IndexPath } from '@ui-kitten/components';

import HomeNav from './HomeNav';
import UserNav from './UserNav';
import ScheduleNav from './ScheduleNav';
import SettingNav from './SettingNav';
import { isAdmin } from '../services/Auth';

const Nav = createDrawerNavigator();

export default function MainNav() {
  const [show, setShow] = useState(false);
  useEffect(() => {
    isAdmin().then((res) => {
      setShow(res);
    });
  }, []);
  const DrawerContent = ({ navigation, state }) => (
    <Drawer
      selectedIndex={new IndexPath(state.index)}
      onSelect={(index) => navigation.navigate(state.routeNames[index.row])}
    >
      <DrawerItem title="Home" />
      <DrawerItem title="Schedule" />
      {show && <DrawerItem title="Users" />}
      <DrawerItem title="Settings" />
    </Drawer>
  );
  return (
    <Nav.Navigator
      screenOptions={{ headerShown: false }}
      drawerContent={(props) => <DrawerContent {...props} />}
    >
      <Nav.Screen name="Home" component={HomeNav} />
      <Nav.Screen name="Schedule" component={ScheduleNav} />
      {show && <Nav.Screen name="UserNav" component={UserNav} />}
      <Nav.Screen name="SettingsNav" component={SettingNav} />
    </Nav.Navigator>
  );
}
