import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { Card, useTheme, ListItem, Text } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/native';

import { LOG } from '../utils/logger';
import {
  getSchedules,
  getSchedulesByLocation,
  getSchedulesByEmployee,
} from '../services/Schedules';
import { getLocations } from '../services/Locations';
import ScheduleCard from './ScheduleCard';
import { isAdmin } from '../services/Auth';

export default function DayCard(props) {
  const [admin, setAdmin] = React.useState(false);
  const [schedules, setSchedules] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const theme = useTheme();
  const navigation = useNavigation();

  const fetchSchedules = () => {
    let startTime = new Date(props.date).setHours(0, 0, 0, 0);
    let endTime = new Date(props.date).setHours(23, 59, 59, 999);

    let promise;

    if (props.filter) {
      if (props.filter.type === 'user') {
        promise = getSchedulesByEmployee(props.filter.id, startTime, endTime);
      } else if (props.filter.type === 'location') {
        promise = getSchedulesByLocation(props.filter.id, startTime, endTime);
      } else {
        promise = getSchedules(startTime, endTime);
      }
    } else {
      promise = getSchedules(startTime, endTime);
    }
    promise
      .then((res) => {
        const docs = res.documents;
        docs.sort((a, b) => {
          return a.locationId > b.locationId ? 1 : -1;
        });
        setSchedules(docs);
      })
      .catch((e) => {
        LOG.warn(e);
      });
  };

  React.useEffect(() => {
    fetchSchedules();
    getLocations().then((l) => {
      setLocations(l.documents);
    });
    isAdmin().then((res) => {
      setAdmin(res);
    });
  }, [props.date, props.filter]);

  const renderDate = (date) => {
    let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return (
      <View style={styles.dateContainer}>
        <Text style={styles.dateNum}>{date.getDate()}</Text>
        <Text style={styles.dateText}>{weekday[date.getDay()].toUpperCase()}</Text>
      </View>
    );
  };

  const isToday = () => {
    let today = new Date();
    return (
      today.getDate() === props.date.getDate() &&
      today.getMonth() === props.date.getMonth() &&
      today.getFullYear() === props.date.getFullYear()
    );
  };

  const renderSchedules = () => {
    if (schedules.length !== 0) {
      var locs = schedules.map((item) => item.locationId).filter((v, i, a) => a.indexOf(v) === i);
      return locs.map((location, index) => {
        const filteredSchedules = schedules.filter((item) => item.locationId === location);
        const locInfo = locations.find((item) => item.$id === location);
        if (locInfo) {
          return (
            <Card
              style={[styles.card]}
              key={index}
              header={
                <View>
                  <Text style={{ padding: 5, backgroundColor: locInfo.color }}>
                    {locInfo.name} : {filteredSchedules.length}
                  </Text>
                </View>
              }
            >
              {filteredSchedules.map((item) => (
                <ScheduleCard key={item.$id} schedule={item} />
              ))}
            </Card>
          );
        }
      });
    }
  };

  const onLongPress = () => {
    if (admin) {
      navigation.navigate('AddSchedule', { date: props.date.getTime() });
    }
  };

  return (
    <ListItem
      onLongPress={onLongPress}
      style={isToday() ? [styles.today, { borderColor: theme['color-primary-default'] }] : null}
    >
      {renderDate(props.date)}
      <View style={styles.schedule}>{renderSchedules()}</View>
    </ListItem>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  dateNum: {
    fontSize: Platform.OS === 'web' ? '10vw' : 40,
    textAlign: 'center',
  },
  dateText: {
    textAlign: 'center',
  },
  card: {
    marginVertical: 5,
  },
  schedule: {
    flex: 1,
    paddingLeft: 10,
  },
  today: {
    borderWidth: 0.5,
  },
});
