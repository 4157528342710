import React from 'react';
import {
  List,
  Layout,
  StyleService,
  useStyleSheet,
  TopNavigationAction,
  Divider,
  TabBar,
  Tab,
  OverflowMenu,
  MenuItem,
} from '@ui-kitten/components';
import { useIsFocused } from '@react-navigation/native';
import { View } from 'react-native';

import TopNav from '../../components/TopNav';
import DayCard from '../../components/DayCard';
import MonthPicker from '../../components/MonthPicker';
import WeekPicker from '../../components/WeekPicker';
import CalendarIcon from '../../components/CalendarIcon';
import Filter from '../../components/Filter';
import CustomIcon from '../../components/CustomIcon';
import { isAdmin, getLoggedInUser } from '../../services/Auth';
import { LOG } from '../../utils/logger';

export default function ListSchedule({ route, navigation }) {
  const isFocused = useIsFocused();
  const styles = useStyleSheet(themedStyles);
  const [filter, setFilter] = React.useState(null);
  const [admin, setAdmin] = React.useState(false);

  const [overflowVisible, setOverflowVisible] = React.useState(false);

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const [dates, setDates] = React.useState([]);
  const [currentDate, setCurrentDate] = React.useState(new Date());

  const ONE_WEEK_MILLISECONDS = 604800000;

  const getDates = () => {
    let curr = new Date(currentDate);
    // set dates range for this week
    let week = [];
    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first));
      week.push(day);
    }
    setDates(week);
  };

  React.useEffect(() => {
    getLoggedInUser()
      .then((user) => {
        if (filter === null) setFilter({ type: 'user', id: user.userId });
        getDates();
      })
      .catch((error) => {
        LOG.warn(error);
      });
    isAdmin()
      .then((res) => {
        setAdmin(res);
      })
      .catch((error) => {
        LOG.warn(error);
      });
  }, [isFocused, currentDate, route.params, selectedIndex]);

  const decrementWeek = () => {
    const lastWeek = new Date(currentDate.getTime() - ONE_WEEK_MILLISECONDS);
    setCurrentDate(lastWeek, getDates());
  };

  const incrementWeek = () => {
    const nextWeek = new Date(currentDate.getTime() + ONE_WEEK_MILLISECONDS);
    setCurrentDate(nextWeek, getDates());
  };

  const setToday = () => {
    const today = new Date();
    setCurrentDate(today, getDates());
  };

  const onMenuSelect = (index) => {
    const row = index.row;
    if (admin) {
      if (row === 0) {
        navigation.navigate('AddSchedule');
      } else if (row === 1) {
        navigation.navigate('OffRequest');
      }
    } else {
      if (row === 0) {
        navigation.navigate('OffRequest');
      }
    }
    setOverflowVisible(false);
  };

  const openOverflowMenu = () => {
    return (
      <OverflowMenu
        anchor={() => (
          <TopNavigationAction
            icon={<CustomIcon name="plus" onPress={() => setOverflowVisible(true)} />}
          />
        )}
        visible={overflowVisible}
        onSelect={onMenuSelect}
        onBackdropPress={() => setOverflowVisible(false)}
      >
        {admin ? <MenuItem title="Add Schedule" /> : null}
        <MenuItem title="Request Off" />
      </OverflowMenu>
    );
  };

  const onFilter = (filter) => {
    setFilter(filter);
  };

  const renderRightAction = () => {
    return (
      <View style={styles.rightAction}>
        <Filter onFilter={onFilter} />
        <CalendarIcon onPress={setToday} />
        {openOverflowMenu()}
      </View>
    );
  };

  const renderTabBar = () => (
    <TabBar selectedIndex={selectedIndex} onSelect={(index) => onSelectIndex(index)}>
      <Tab title="My Schedules" />
      <Tab title="All Schedules" />
    </TabBar>
  );
  const onSelectIndex = (index) => {
    setSelectedIndex(index);
    if (index === 0) {
      getLoggedInUser()
        .then((user) => {
          setFilter({ type: 'user', id: user.userId });
          getDates();
        })
        .catch((error) => {
          LOG.warn(error);
        });
    }
    if (index === 1) {
      setFilter({ type: 'all' });
      getDates();
    }
  };

  const renderDay = ({ item }) => <DayCard date={item} filter={filter} />;

  return (
    <Layout style={styles.container}>
      <TopNav title="Schedule" leftAction={'menu'} rightAction={renderRightAction()} />
      {renderTabBar()}

      <MonthPicker currentDate={currentDate} setCurrentDate={setCurrentDate} />
      <WeekPicker
        onPressLeft={decrementWeek}
        onPressRight={incrementWeek}
        currentDate={currentDate}
      />
      <Divider />

      {dates.length > 0 ? (
        <List
          style={styles.datesContainer}
          data={dates}
          renderItem={renderDay}
          ItemSeparatorComponent={Divider}
        />
      ) : null}
    </Layout>
  );
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    position: 'relative',
  },
  datesContainer: {
    flex: 1,
  },
  rightAction: {
    flexDirection: 'row',
  },
});
