import React from 'react';
import { useColorScheme } from 'react-native';
import * as eva from '@eva-design/eva';
import { IconRegistry, ApplicationProvider } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';

import { default as theme } from './constants/theme.json';
import RootNav from './navigations/RootNav';
import Toast from './components/Toast';

export default function App() {
  let colorScheme = useColorScheme();
  const evaTheme = colorScheme === 'dark' ? eva.dark : eva.light;
  return (
    <>
      <IconRegistry icons={EvaIconsPack} />
      <ApplicationProvider {...eva} theme={{ ...evaTheme, ...theme }}>
        <Toast />
        <RootNav />
      </ApplicationProvider>
    </>
  );
}
