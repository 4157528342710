import React from 'react';
import { View } from 'react-native';
import { Layout, StyleService, useStyleSheet, TopNavigationAction } from '@ui-kitten/components';

import { LOG } from '../../utils/logger';
import TopNav from '../../components/TopNav';
import Loading from '../../components/Loading';

import AlertModal from '../../components/AlertModal';
import InputEmployee from '../../components/InputEmployee';
import InputDate from '../../components/InputDate';
import InputTime from '../../components/InputTime';
import InputLocation from '../../components/InputLocation';
import InputNote from '../../components/InputNote';
import InputStatus from '../../components/InputStatus';
import CustomIcon from '../../components/CustomIcon';
import { getSchedule, deleteSchedule } from '../../services/Schedules';
import { getUserMembership } from '../../services/Users';
import { isAdmin } from '../../services/Auth';

export default function ViewSchedule({ route, navigation }) {
  const styles = useStyleSheet(themedStyles);

  const [schedule, setSchedule] = React.useState({});
  const [user, setUser] = React.useState();
  const [admin, setAdmin] = React.useState(false);
  const [alertVisible, setAlertVisible] = React.useState(false);

  React.useEffect(() => {
    isAdmin().then((res) => {
      setAdmin(res);
    });
    if (route.params) {
      getSchedule(route.params.scheduleId)
        .then((res) => {
          setSchedule(res);
          getUserMembership(res.userId).then((r) => {
            setUser(r.memberships[0]);
          });
        })
        .catch((e) => LOG.warn(e));
    }
  }, [route.params]);

  const editSchedule = () => {
    navigation.navigate('EditSchedule', { scheduleId: schedule.$id });
  };

  const renderRightAction = () => {
    if (admin) {
      return (
        <View style={styles.rightAction}>
          <TopNavigationAction
            icon={<CustomIcon name="trash-outline" onPress={() => setAlertVisible(true)} />}
          />
          <TopNavigationAction icon={<CustomIcon name="edit-outline" onPress={editSchedule} />} />
        </View>
      );
    }
  };

  return (
    <Layout style={styles.container}>
      <TopNav title="Schedule" leftAction={'back'} rightAction={renderRightAction()} />
      <AlertModal
        visible={alertVisible}
        setVisible={setAlertVisible}
        title="Delete Schedule?"
        onAccept={() => {
          deleteSchedule(schedule.$id);
          navigation.navigate('Schedules');
        }}
      />
      {user === undefined ? (
        <Loading />
      ) : (
        <>
          <InputEmployee employeeId={user.userId} />
          <InputDate date={new Date(schedule.startTime)} />
          <InputTime startTime={schedule.startTime} endTime={schedule.endTime} />
          <InputLocation locationId={schedule.locationId} />
          {schedule.status && <InputStatus status={schedule.status} />}
          {schedule.note ? <InputNote note={schedule.note} /> : null}
        </>
      )}
    </Layout>
  );
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  icon: {
    width: 32,
    height: 32,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  description: {
    paddingVertical: 5,
    fontSize: 14,
  },
  info: {
    marginHorizontal: 10,
    width: '90%',
    borderBottomWidth: 1,
    borderBottomColor: 'gray',
  },
  rightAction: {
    flexDirection: 'row',
  },
});
