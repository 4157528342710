import React from 'react';
import { View } from 'react-native';
import { Layout, StyleService, Text, useStyleSheet, Spinner } from '@ui-kitten/components';

import { getUser, updateUser } from '../../services/Users';
import { getLoggedInUser, isAdmin } from '../../services/Auth';

import TopNav from '../../components/TopNav';
import DotMenu from '../../components/DotMenu';

import { LOG } from '../../utils/logger';

export default function ViewUser({ route }) {
  const styles = useStyleSheet(themedStyles);
  const [user, setUser] = React.useState();
  const [title, setTitle] = React.useState('User');
  const [status, setStatus] = React.useState('active');
  const [admin, setAdmin] = React.useState(false);
  const [myProfile, setMyProfile] = React.useState(false);

  React.useEffect(() => {
    function settingUser(userId) {
      getUser(userId)
        .then((res) => {
          LOG.debug(res);
          if (res.statusCode === 200) {
            var usr = JSON.parse(res.response);
            LOG.debug(usr);
            setUser(usr);
            setStatus(usr.status);
          } else {
            LOG.warn(res);
          }
        })
        .catch((e) => LOG.warn(e));
    }
    isAdmin().then((res) => {
      setAdmin(res);
    });

    if (route.params) {
      setUser(route.params.user);
      setStatus(route.params.user.status);
      setTitle(route.params.user.name);
    } else {
      getLoggedInUser()
        .then((u) => {
          settingUser(u.userId);
          setTitle('My Profile');
          setMyProfile(true);
        })
        .catch((e) => LOG.warn(e));
    }
  }, []);

  function ProfileField(props) {
    if (props.value) {
      return (
        <Layout level="1" style={[styles.profileField, props.style]}>
          <Text appearance="hint" category="s1">
            {props.title}
          </Text>
          <Text category="s1">{props.value}</Text>
        </Layout>
      );
    }
  }

  const renderProfile = () => {
    return (
      <>
        <ProfileField title="Name" value={user.name} style={[styles.setting]} />
        <ProfileField title="Email" value={user.email} style={[styles.setting]} />
        <ProfileField title="Phone" value={user.phone} style={[styles.setting]} />
        <ProfileField title="Birthday" value={user.dob} style={[styles.setting]} />
        <ProfileField title="Status" value={status} style={[styles.setting]} />
      </>
    );
  };

  const actions = [
    {
      title: `Set ${user && user.status === 'inactive' ? 'Active' : 'Inactive'}`,
      onSelect: () => {
        // by default empty status means active
        user.status = user.status === 'inactive' ? 'active' : 'inactive';
        updateUser({ userId: user.$id, status: user.status });
        setUser(user);
        setStatus(user.status);
      },
    },
  ];

  return (
    <Layout style={styles.container}>
      <TopNav title={title} leftAction={'back'} rightAction={<DotMenu actions={actions} />} />

      <Layout style={styles.profileContainer}>
        {user ? (
          renderProfile()
        ) : (
          <View style={styles.spinner}>
            <Spinner size="giant" />
          </View>
        )}
      </Layout>
    </Layout>
  );
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  avatar: {
    alignSelf: 'center',
    width: 320,
    height: 320,
    borderRadius: 16,
  },
  spinner: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 100,
  },
  photoButton: {
    right: '15%',
    top: 32,
    width: 48,
    height: 48,
    borderRadius: 24,
    position: 'absolute',
    alignSelf: 'flex-end',
    bottom: 0,
  },
  profileField: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  setting: {
    padding: 16,
  },
  section: {
    marginTop: 24,
  },
});
