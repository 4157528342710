import React, { useState } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { Layout, Text, Input, Button } from '@ui-kitten/components';
import { Account } from 'appwrite';

import TopNav from '../components/TopNav';

import { APPWRITE } from '../constants/constants';
import client from '../utils/appwrite';
// import { LOG } from '../utils/logger';

export default function Forgot({ navigation }) {
  const [email, setEmail] = useState();
  const [message, setMessage] = useState('Send Login Link');
  const account = new Account(client);

  const handleForgot = () => {
    setMessage('Link Sent');
    account.createRecovery(email, APPWRITE.host + '/change-password');
    navigation.replace('Login');
  };

  return (
    <Layout style={styles.container}>
      <TopNav title="Forgot Password" leftAction={'back'} />

      <Layout style={styles.innerContainer}>
        <Text style={styles.infoText}>
          Enter your email and we&apos;ll send you a link to get back into your account.
        </Text>
        <Input
          style={styles.input}
          autoCapitalize="none"
          placeholder="Email"
          value={email}
          onChangeText={setEmail}
        />
        <Button onPress={handleForgot} style={styles.button} disabled={!email}>
          {message}
        </Button>
      </Layout>
    </Layout>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    alignItems: 'center',
    marginTop: 50,
    padding: 20,
  },
  infoText: {
    textAlign: 'center',
    width: '100%',
    ...Platform.select({ web: { maxWidth: 500 } }),
  },
  input: {
    marginTop: 20,
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100%',
    ...Platform.select({ web: { maxWidth: 500 } }),
  },
  button: {
    marginTop: 20,
    width: '100%',
    ...Platform.select({ web: { maxWidth: 500 } }),
  },
});
