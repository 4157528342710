// function to get first day of the current week
const getFirstDayOfWeek = (givenDate) => {
  const FIRST_OF_WEEK = 1; // monday is first day of the week
  const first = new Date(
    givenDate.setDate(givenDate.getDate() - givenDate.getDay() + FIRST_OF_WEEK)
  );
  first.setHours(0, 0, 0, 0);
  return first;
};

// function to get last day of the current week
const getLastDayOfWeek = (givenDate) => {
  const LAST_OF_WEEK = 7; // sunday is last day of the week
  const last = new Date(givenDate.setDate(givenDate.getDate() - givenDate.getDay() + LAST_OF_WEEK));
  last.setHours(23, 59, 59, 999);
  return last;
};

// format time to am pm
const formatTime = (time) => {
  return new Date(time).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
};

// format milliseconds to timepicker format
const millisToTimePicker = (time) => {
  const tm = new Date(time);
  const hours = tm.getHours();
  const minutes = tm.getMinutes();
  return {
    hours: hours > 12 ? hours - 12 : hours,
    minutes: minutes,
    ampm: hours >= 12 ? 'pm' : 'am',
  };
};

// format timepicker to milliseconds
const timePickerToMillis = (time) => {
  const hours = time.hours;
  const minutes = time.minutes;
  const ampm = time.ampm;
  const tm = new Date();
  tm.setHours(ampm === 'pm' ? hours + 12 : hours);
  tm.setMinutes(minutes);
  tm.setSeconds(0);
  tm.setMilliseconds(0);
  return tm.getTime();
};

export { getFirstDayOfWeek, getLastDayOfWeek, formatTime, millisToTimePicker, timePickerToMillis };
