import { Teams, Query, Account, Functions, Databases } from 'appwrite';
import { v4 } from 'uuid';

import { LOG } from '../utils/logger';
import { APPWRITE } from '../constants/constants';
import client from '../utils/appwrite';

const teams = new Teams(client);
const account = new Account(client);
const functions = new Functions(client);
const db = new Databases(client);

const DATABASE_ID = 'scheduling';
const COLLECTION_ID = '64e2bcafd74869c165f5';

// list all users in membership
// memberships is is response.memberships
// userIds is array of userIds
const listUsers = (userIds) => {
  if (userIds) {
    var queries = [];
    userIds.forEach((userId) => {
      queries.push(Query.equal('userId', userId));
    });
    return teams.listMemberships(APPWRITE.teamId, queries);
  } else {
    return teams.listMemberships(APPWRITE.teamId);
  }
};

// get user from membership
const getUserMembership = (userId) => {
  // LOG.debug('team ID' + APPWRITE.teamId);
  return teams.listMemberships(APPWRITE.teamId, [Query.equal('userId', userId)]);
};

// get user info
const getUser = (userId) => {
  return functions.createExecution('getUser', userId);
};

const getUsers = () => {
  LOG.debug(`getUsers: ${DATABASE_ID} ${COLLECTION_ID}`);
  return db.listDocuments(DATABASE_ID, COLLECTION_ID, [Query.orderAsc('name')]);
};

const getActiveUsers = () => {
  return getUsers().then((response) => {
    const data = response.documents;
    return data;
  });
};

const getInactiveUsers = async () => {
  try {
    const response = await getUsers();
    const data = JSON.parse(response.response);
    const filteredData = data
      .filter((item) => item.status === 'inactive')
      .sort((a, b) => a.name.localeCompare(b.name));
    return filteredData;
  } catch (e) {
    LOG.warn(e);
    throw e;
  }
};

const createUser = async (user, onSuccess, onFail) => {
  try {
    const uniqueId = v4().replace(/-/g, '');
    var ac = await account.create(uniqueId, user.email, v4(), user.name);
    db.createDocument(DATABASE_ID, COLLECTION_ID, uniqueId, {
      name: user.name,
      nickname: user.nickname,
    });
    LOG.debug(ac);
    account.createRecovery(user.email, APPWRITE.host + '/change-password');
    onSuccess();
  } catch (error) {
    onFail(error);
  }
};

// update user
// {
//   "userId": "user-id",
//   "status": "inactive"
//  }
const updateUser = (user) => {
  return functions.createExecution('updateUser', JSON.stringify(user));
};

export {
  listUsers,
  getUserMembership,
  createUser,
  getUser,
  updateUser,
  getUsers,
  getActiveUsers,
  getInactiveUsers,
};
