// Input Template
import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';

// Props
// title: string
//  The title of the input
// content: string
//  The content of the input
// onPress: function
//  The function to call when the input is pressed
// color: string
//  The color of the label
// icon: string
//  The name of the icon to display
export default function InputBox(props) {
  return (
    <TouchableOpacity
      style={styles.container}
      disabled={!props.onPress || props.onPress === null}
      onPress={props.onPress}
    >
      <Icon color=""
        style={styles.icon}
        fill={props.color ? props.color : 'white'}
        name={props.icon ? props.icon : 'star'}
      />
      <View style={styles.inputContainer}>
        <Text style={[styles.inputLabel, { color: props.color }]}>
          {props.title ? props.title : 'Title'}
        </Text>
        <Text style={[styles.inputContent]}>{props.content ? props.content : ''}</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    flexDirection: 'row',
    maxHeight: 75,
    alignItems: 'center',
  },
  icon: {
    width: 32,
    height: 32,
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'column',
    marginHorizontal: 10,
    paddingBottom: 5,
    borderBottomColor: 'gray',
    borderBottomWidth: 1,
    justifyContent: 'space-between',
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
});
