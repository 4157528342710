import { Account } from 'appwrite';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { LOG } from '../utils/logger';
import { APPWRITE, APPWRITE_SERVER } from '../constants/constants';
import client from '../utils/appwrite';
import { getUserMembership } from '../services/Users';

const account = new Account(client);

const logout = (navigation) => {
  LOG.debug('Logging Out');
  AsyncStorage.removeItem('@user')
    .then(() => {
    account.deleteSession('current')
      .catch((e) => LOG.error(e));
  });
  navigation.replace('Splash')
};

const login = (navigation, email, password, setErrorMessage) => {
  account
    .createEmailSession(email, password)
    .then((response) => {
      LOG.debug(response);
      setErrorMessage();
      getUserMembership(response.userId)
        .then((response) => {
          LOG.debug(response);
          var membership = response.memberships;
          if (response.total) {
            var userObj = membership[0];
            userObj.appwriteServer = APPWRITE_SERVER;
            const jsonValue = JSON.stringify(userObj);
            AsyncStorage.setItem('@user', jsonValue)
              .then(() => navigation.replace('Splash'))
              .catch((e) => LOG.error(e));
          } else {
            setErrorMessage('User cannot be found');
          }
        })
        .catch((error) => {
          LOG.warn(error);
          setErrorMessage(error.message);
        });
    })
    .catch((error) => {
      LOG.warn(error);
      setErrorMessage(error.message);
    });
};

const getLoggedInUser = async () => {
  const jsonValue = await AsyncStorage.getItem('@user');
  return jsonValue != null ? JSON.parse(jsonValue) : null;
};

const isAdmin = () => {
  return getLoggedInUser().then((user) => {
    return user.roles.includes(APPWRITE.adminRole);
  });
};

export { login, logout, getLoggedInUser, isAdmin };
