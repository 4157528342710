import React, { useState } from 'react';
import { View, Pressable, Platform, StyleSheet } from 'react-native';
import { Layout, Text, Input, Button, Icon } from '@ui-kitten/components';
import { Account } from 'appwrite';

import TopNav from '../components/TopNav';

import client from '../utils/appwrite';
import { LOG } from '../utils/logger';

export default function ChangePwd({ route, navigation }) {
  const [password, setPassword] = useState('');
  const [secureTextEntry, setSecureTextEntry] = React.useState(true);
  const [message, setMessage] = useState('Update Password');
  const account = new Account(client);

  const handleUpdate = () => {
    account
      .updateRecovery(route.params.userId, route.params.secret, password, password)
      .then((response) => LOG.debug(response))
      .catch((e) => {
        LOG.warn(e);
        setMessage(e);
      });
    navigation.navigate('Login');
  };
  const renderIcon = (props) => (
    <Pressable onPress={toggleSecureEntry}>
      <Icon {...props} color="" name={secureTextEntry ? 'eye-off' : 'eye'} />
    </Pressable>
  );

  const toggleSecureEntry = () => {
    setSecureTextEntry(!secureTextEntry);
  };
  const renderCaption = () => {
    if (password.length > 0 && password.length < 8) {
      return (
        <View style={styles.captionContainer}>
          <Text status="danger" style={styles.captionText}>
            Should contain at least 8 symbols
          </Text>
        </View>
      );
    }
  };
  return (
    <Layout style={styles.container}>
      <TopNav title="Change Password" />

      <Layout style={styles.innerContainer}>
        <Text style={styles.infoText}>Enter your new password</Text>
        <Input
          style={styles.input}
          value={password}
          placeholder="Password"
          caption={renderCaption}
          accessoryRight={renderIcon}
          secureTextEntry={secureTextEntry}
          onChangeText={setPassword}
        />
        <Button onPress={handleUpdate} style={styles.button} disabled={!password}>
          {message}
        </Button>
      </Layout>
    </Layout>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    alignItems: 'center',
    marginTop: 50,
    padding: 20,
  },
  infoText: {
    textAlign: 'center',
    width: '100%',
    ...Platform.select({ web: { maxWidth: 500 } }),
  },
  input: {
    marginTop: 20,
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100%',
    ...Platform.select({ web: { maxWidth: 500 } }),
  },
  button: {
    marginTop: 20,
    width: '100%',
    ...Platform.select({ web: { maxWidth: 500 } }),
  },
  captionText: {
    paddingTop: 10,
  },
});
