import React from 'react';
import { StyleSheet, Text, useColorScheme, TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export default function CalendarIcon(props) {
  const colorScheme = useColorScheme();
  const [currentDay, setCurrentDay] = React.useState('');

  React.useEffect(() => {
    const today = new Date();
    setCurrentDay(today.getDate());
  }, []);

  const iconColor = colorScheme === 'dark' ? 'white' : 'black';
  return (
    <TouchableOpacity onPress={props.onPress} style={styles.calendar}>
      <MaterialCommunityIcons
        name="calendar-blank"
        size={30}
        color={iconColor}
        style={styles.calendarIcon}
      />
      <Text style={[styles.date, { color: iconColor }]}>{currentDay}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  calendar: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
  },
  calendarIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  date: {
    fontSize: 9,
    marginTop: 4,
  },
});
