import { Databases, Query } from 'appwrite';

import client from '../utils/appwrite';

const db = new Databases(client);
const DATABASE_ID = 'scheduling';
const SCHEDULES_COLLECTION_ID = 'employee-schedule';

// get schedules given start and end time in millis
const getSchedules = (start, end) => {
  const queries = [Query.greaterThanEqual('startTime', start), Query.lessThanEqual('endTime', end)];

  return db.listDocuments(DATABASE_ID, SCHEDULES_COLLECTION_ID, queries);
};

const getSchedulesByEmployee = (userId, start, end) => {
  const queries = [
    Query.equal('userId', userId),
    Query.greaterThanEqual('startTime', start),
    Query.lessThanEqual('endTime', end),
  ];

  return db.listDocuments(DATABASE_ID, SCHEDULES_COLLECTION_ID, queries);
};

const getSchedulesByLocation = (locationId, start, end) => {
  const queries = [
    Query.equal('locationId', locationId),
    Query.greaterThanEqual('startTime', start),
    Query.lessThanEqual('endTime', end),
  ];

  return db.listDocuments(DATABASE_ID, SCHEDULES_COLLECTION_ID, queries);
};

const getSchedulesByEmployeeAndLocation = (userId, locationId, start) => {
  const queries = [
    Query.equal('userId', userId),
    Query.equal('locationId', locationId),
    Query.greaterThanEqual('startTime', start),
  ];

  return db.listDocuments(DATABASE_ID, SCHEDULES_COLLECTION_ID, queries);
};

// no need for location because only OFF has pending
const getSchedulesByStatus = (status, start) => {
  const queries = [Query.equal('status', status), Query.greaterThanEqual('startTime', start)];

  return db.listDocuments(DATABASE_ID, SCHEDULES_COLLECTION_ID, queries);
};

const addSchedule = (schedule) => {
  return db.createDocument(DATABASE_ID, SCHEDULES_COLLECTION_ID, 'unique()', schedule);
};

const getSchedule = (scheduleId) => {
  return db.getDocument(DATABASE_ID, SCHEDULES_COLLECTION_ID, scheduleId);
};

const deleteSchedule = (scheduleId) => {
  return db.deleteDocument(DATABASE_ID, SCHEDULES_COLLECTION_ID, scheduleId);
};

const updateSchedule = (scheduleId, schedule) => {
  return db.updateDocument(DATABASE_ID, SCHEDULES_COLLECTION_ID, scheduleId, schedule);
};

export {
  getSchedules,
  addSchedule,
  getSchedule,
  deleteSchedule,
  updateSchedule,
  getSchedulesByEmployee,
  getSchedulesByLocation,
  getSchedulesByEmployeeAndLocation,
  getSchedulesByStatus,
};
