import React, { useEffect } from 'react';
import { TouchableOpacity } from 'react-native';
import {
  List,
  useTheme,
  useStyleSheet,
  StyleService,
  Card,
  Layout,
  Text,
} from '@ui-kitten/components';

import TopNav from '../components/TopNav';

import { isAdmin, getLoggedInUser } from '../services/Auth';
import {
  getSchedulesByEmployee,
  getSchedulesByEmployeeAndLocation,
  getSchedulesByStatus,
} from '../services/Schedules';
import { getLocations } from '../services/Locations';
import { getActiveUsers } from '../services/Users';

import { APPWRITE_SERVER } from '../constants/constants';

export default function Home({ navigation }) {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();

  const OFF_LOCATION_ID = '63acaf8a2798d338b565';

  const [admin, setAdmin] = React.useState(false);
  const [schedules, setSchedules] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [offRequests, setOffRequests] = React.useState([]);
  const [pendingRequests, setPendingRequests] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);

  const fetchData = () => {
    isAdmin().then((res) => {
      setAdmin(res);
    });
    getActiveUsers().then((res) => {
      setEmployees(res);
    });
    getLoggedInUser().then((u) => {
      if (!u || u.appwriteServer !== APPWRITE_SERVER) {
        navigation.replace('Login');
      }
      const today = Date.now();
      const nextWeekMillis = 7 * 24 * 60 * 60 * 1000;
      getSchedulesByEmployee(u.userId, today, today + nextWeekMillis).then((s) => {
        setSchedules(s.documents);
      });
      getSchedulesByEmployeeAndLocation(u.userId, OFF_LOCATION_ID, today).then((s) => {
        setOffRequests(s.documents);
      });
    });

    getSchedulesByStatus('pending', Date.now()).then((s) => {
      // sort by start time
      const pending = s.documents.sort((a, b) => {
        return a.startTime - b.startTime;
      });
      setPendingRequests(pending);
    });

    getLocations().then((l) => {
      setLocations(l.documents);
    });
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      fetchData();
    });
    return unsubscribe;
  }, [navigation]);

  const getLocationName = (id) => {
    const location = locations.find((l) => l.$id === id);
    return location ? location.name : '';
  };

  const getEmployeeName = (id) => {
    const employee = employees.find((e) => e.$id === id);
    // nickname to capitalize
    return employee ? employee.nickname.replace(/\b\w/g, (l) => l.toUpperCase()) : '';
  };

  const getLocationColor = (id) => {
    const location = locations.find((l) => l.$id === id);
    return location ? location.color : theme['color-primary-500'];
  };

  const renderSchedules = () => {
    const renderTitle = (item) => {
      const startTime = new Date(item.startTime);
      const endTime = new Date(item.endTime);
      const startDateStr = startTime.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        weekday: 'short',
      });
      const startTimeStr = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const endTimeStr = endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const location = getLocationName(item.locationId);
      return `${startDateStr} ${startTimeStr} - ${endTimeStr} - ${location}`;
    };

    const renderSchedule = ({ item, index }) => (
      <Layout>
        <Text key={index} style={[styles.item, { color: getLocationColor(item.locationId) }]}>
          {renderTitle(item)}
        </Text>
      </Layout>
    );

    return (
      <Card onPress={() => navigation.navigate('Schedule')} style={styles.card} status="primary">
        <Text category="h6">My Schedules (7 Days)</Text>
        {schedules.length > 0 ? (
          <List style={styles.list} data={schedules} renderItem={renderSchedule} />
        ) : (
          <Text style={styles.item}>No schedules found this week.</Text>
        )}
      </Card>
    );
  };

  const renderOffRequests = () => {
    const getColor = (status) => {
      switch (status) {
        case 'pending':
          return theme['color-warning-500'];
        case 'approved':
          return theme['color-success-500'];
        case 'denied':
          return theme['color-danger-500'];
        default:
          return theme['color-primary-500'];
      }
    };

    const renderRequest = ({ item, index }) => (
      <Layout>
        <Text key={index} style={[styles.item, { color: getColor(item.status) }]}>
          {`${new Date(item.startTime).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            weekday: 'short',
          })} - ${item.note} - ${item.status}`}
        </Text>
      </Layout>
    );
    return (
      <Card disabled style={styles.card} status="info">
        <Text category="h6">My Off Requests</Text>
        {offRequests.length > 0 ? (
          <List style={styles.list} data={offRequests} renderItem={renderRequest} />
        ) : (
          <Text style={styles.item}>No off requests found.</Text>
        )}
      </Card>
    );
  };

  const renderPendingRequests = () => {
    const renderRequest = ({ item, index }) => (
      <Layout>
        <TouchableOpacity
          onPress={() => navigation.navigate('EditSchedule', { scheduleId: item.$id })}
        >
          <Text key={index} style={[styles.item]}>
            {`${new Date(item.startTime).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              weekday: 'short',
            })} - ${getEmployeeName(item.userId)} - ${item.note}`}
          </Text>
        </TouchableOpacity>
      </Layout>
    );
    return (
      <Card style={styles.card} status="warning">
        <Text category="h6">Pending Requests</Text>
        {pendingRequests.length > 0 ? (
          <List style={styles.list} data={pendingRequests} renderItem={renderRequest} />
        ) : (
          <Text style={styles.item}>No off requests found.</Text>
        )}
      </Card>
    );
  };

  return (
    <Layout style={styles.container} level="1">
      <TopNav title="Home" leftAction={'menu'} />
      <Layout style={styles.layout}>
        {renderSchedules()}
        {renderOffRequests()}
        {admin && renderPendingRequests()}
      </Layout>
    </Layout>
  );
}
const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  layout: {
    flex: 1,
    height: '100%',
    padding: 20,
  },
  card: {
    marginVertical: 10,
    width: '100%',
  },
  item: {
    padding: 10,
    backgroundColor: 'transparent',
  },
});
