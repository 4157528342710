// add schedule
import React from 'react';
import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';

import AlertModal from '../../components/AlertModal';
import TopNav from '../../components/TopNav';
import InputEmployee from '../../components/InputEmployee';
import InputDate from '../../components/InputDate';
import InputTime from '../../components/InputTime';
import InputLocation from '../../components/InputLocation';
import InputNote from '../../components/InputNote';

import { isAdmin } from '../../services/Auth';
import { addSchedule } from '../../services/Schedules';

import { LOG } from '../../utils/logger';

export default function AddSchedule({ route, navigation }) {
  const styles = useStyleSheet(themedStyles);
  const [locationId, setLocationId] = React.useState();
  const [scheduleDate, setScheduleDate] = React.useState();
  const [alertVisible, setAlertVisible] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState('');

  // set default start and end time
  const [startTime, setStartTime] = React.useState(new Date().setHours(8, 0, 0, 0));
  const [endTime, setEndTime] = React.useState(new Date().setHours(17, 0, 0, 0));
  const [note, setNote] = React.useState();

  const [selectedUsers, setSelectedUsers] = React.useState([]);

  const [range, setRange] = React.useState({ startDate: new Date() });

  React.useEffect(() => {
    isAdmin().then((response) => {
      if (!response) {
        navigation.replace('Home');
      }
    });
    setScheduleDate(route.params ? new Date(route.params.date) : new Date());
    setRange({ startDate: route.params ? new Date(route.params.date) : new Date() });
  }, [route.params]);

  const onSave = () => {
    if (selectedUsers.length === 0) {
      setAlertVisible(true);
      setAlertTitle('No Employee Selected');
    } else if (locationId === null || locationId === undefined) {
      setAlertVisible(true);
      setAlertTitle('No Location Selected');
    } else {
      for (let i = 0; i < selectedUsers.length; i++) {
        const startDate = new Date(range.startDate);
        startDate.setHours(0, 0, 0, 0);
        // if there is no endDate then endDate = startDate
        const endDate = range.endDate ? new Date(range.endDate) : new Date(range.startDate);
        endDate.setHours(23, 59, 59, 999);
        let currentDate = startDate;
        while (currentDate <= endDate) {
          let user = selectedUsers[i];
          const dateStartTime = new Date(startTime);
          const dateEndTime = new Date(endTime);
          const scheduleStartTime = new Date(currentDate).setHours(
            dateStartTime.getHours(),
            dateStartTime.getMinutes(),
            0,
            0
          );
          const scheduleEndTime = new Date(currentDate).setHours(
            dateEndTime.getHours(),
            dateEndTime.getMinutes(),
            0,
            0
          );
          LOG.debug({
            userId: user.$id,
            startTime: new Date(scheduleStartTime).toLocaleString(),
            endTime: new Date(scheduleEndTime).toLocaleString(),
            locationId,
          });
          addSchedule({
            userId: user.$id,
            startTime: scheduleStartTime,
            endTime: scheduleEndTime,
            locationId,
          });
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
      navigation.navigate('Schedules');
    }
  };

  const setTime = (start, end) => {
    setStartTime(start);
    setEndTime(end);
  };

  return (
    <Layout style={styles.container}>
      <TopNav
        title="Add Schedule"
        leftAction={'back'}
        rightAction={{ icon: 'save-outline', action: onSave }}
      />
      <InputEmployee multiselect onPress={(e) => setSelectedUsers([...e])} date={range} />
      <InputDate range date={scheduleDate} onPress={setRange} />
      <InputTime onPress={setTime} startTime={startTime} endTime={endTime} />
      <InputLocation locationId={locationId} onPress={(loc) => setLocationId(loc.$id)} />
      <InputNote value={note} setValue={setNote} />
      <AlertModal title={alertTitle} visible={alertVisible} setVisible={setAlertVisible} />
    </Layout>
  );
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});
