// react component for ScheduleCard
import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Text, Icon, useStyleSheet, StyleService, useTheme } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/native';

import Avatar from './Avatar';
import { getUserMembership } from '../services/Users';
import { isAdmin } from '../services/Auth';
import { formatTime } from '../utils/time';
import { LOG } from '../utils/logger';

export default function ScheduleCard(props) {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const schedule = props.schedule;
  const [user, setUser] = useState();
  const [admin, setAdmin] = useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    //get user
    getUserMembership(schedule.userId)
      .then((u) => {
        setUser(u.memberships[0]);
      })
      .catch((e) => LOG.warn(e));
    isAdmin().then((res) => {
      setAdmin(res);
    });
  }, []);

  if (!user) {
    return null;
  }

  const onPress = () => {
    if (admin) {
      navigation.navigate('EditSchedule', { scheduleId: schedule.$id });
    } else {
      navigation.navigate('ViewSchedule', { scheduleId: schedule.$id });
    }
  };

  const renderStatus = () => {
    var color;
    var icon;
    if (schedule.status === 'pending') {
      color = theme['color-warning-500'];
      icon = 'clock-outline';
    } else if (schedule.status === 'approved') {
      color = theme['color-success-500'];
      icon = 'checkmark-circle-2-outline';
    } else if (schedule.status === 'denied') {
      color = theme['color-danger-500'];
      icon = 'close-circle-outline';
    }
    if (color) {
      return <Icon color="" style={styles.statusIcon} fill={color} name={icon} />;
    }
  };

  return (
    <TouchableOpacity onPress={onPress} style={[styles.container]}>
      <View style={styles.avatar}>
        <Avatar name={user.userName} userId={user.userId} />
      </View>
      <View style={styles.textBox}>
        <Text style={[styles.text, { fontWeight: 'bold', fontSize: 16 }]}>{user.userName}</Text>
        <Text style={[styles.text]}>
          {formatTime(schedule.startTime)} - {formatTime(schedule.endTime)}
        </Text>
        {schedule.note && <Text style={[styles.text]}>{schedule.note}</Text>}
      </View>
      <View style={styles.status}>{renderStatus()}</View>
    </TouchableOpacity>
  );
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    borderRadius: 5,
    marginVertical: 2,
    borderTopWidth: 0.2,
    flexWrap: 'wrap',
  },
  textBox: {
    flex: 1,
    paddingHorizontal: 5,
  },
  avatar: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  statusIcon: {
    width: 32,
    height: 32,
    marginTop: 5,
  },
});
