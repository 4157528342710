import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Home from '../screens/Home';
import EditSchedule from '../screens/schedules/Edit';

const Nav = createNativeStackNavigator();

export default function HomeNav() {
  return (
    <Nav.Navigator screenOptions={{ headerShown: false }} initialRouteName="HomeScreen">
      <Nav.Screen name="HomeScreen" component={Home} />
      <Nav.Screen name="EditSchedule" component={EditSchedule} />
    </Nav.Navigator>
  );
}
