import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import ListSchedule from '../screens/schedules/List';
import AddSchedule from '../screens/schedules/Add';
import ViewSchedule from '../screens/schedules/View';
import EditSchedule from '../screens/schedules/Edit';
import SelectEmployees from '../screens/schedules/SelectEmployees';
import OffRequest from '../screens/schedules/OffRequest';

const Nav = createNativeStackNavigator();

export default function ScheduleNav() {
  return (
    <Nav.Navigator screenOptions={{ headerShown: false }} initialRouteName="Schedules">
      <Nav.Screen name="Schedules" component={ListSchedule} />
      <Nav.Screen name="AddSchedule" component={AddSchedule} />
      <Nav.Screen name="ViewSchedule" component={ViewSchedule} />
      <Nav.Screen name="EditSchedule" component={EditSchedule} />
      <Nav.Screen name="SelectEmployees" component={SelectEmployees} />
      <Nav.Screen name="OffRequest" component={OffRequest} />
    </Nav.Navigator>
  );
}
