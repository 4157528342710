import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Settings from '../screens/settings/Settings';
import Versions from '../screens/settings/Versions';
import Logs from '../screens/settings/Logs';

const Nav = createNativeStackNavigator();

export default function SettingNav() {
  return (
    <Nav.Navigator screenOptions={{ headerShown: false }} initialRouteName="Settings">
      <Nav.Screen name="Settings" component={Settings} />
      <Nav.Screen name="Versions" component={Versions} />
      <Nav.Screen name="Logs" component={Logs} />
    </Nav.Navigator>
  );
}
