import { logger, consoleTransport, fileAsyncTransport } from 'react-native-logs';
import { Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';
import { LOG_FILE } from '../constants/constants';

const config = {
  transport: __DEV__ || Platform.OS === 'web' ? consoleTransport : fileAsyncTransport,
  severity: __DEV__ ? 'debug' : 'error',
  transportOptions: {
    colors: {
      debug: 'cyan',
      info: 'blueBright',
      warn: 'yellowBright',
      error: 'redBright',
    },
    FS: FileSystem,
    fileName: LOG_FILE,
    filePath: FileSystem.cacheDirectory,
  },
  dateFormat: 'local',
};

var LOG = logger.createLogger(config);

export { LOG };
