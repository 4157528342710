import React from 'react';
import { Layout, StyleService, useStyleSheet, Modal, Button } from '@ui-kitten/components';
import { View, Dimensions } from 'react-native';

// Props:
// - visible: boolean
// - setVisible: function
// - children: React.ReactNode
// - onSave: function
// - onCancel: function
export default function InputModal(props) {
  const styles = useStyleSheet(themedStyles);
  const width = Dimensions.get('window').width;
  const height = Dimensions.get('window').height;

  const onCancel = () => {
    if (props.onCancel) props.onCancel();
    props.setVisible(false);
  };

  const onSave = () => {
    if (props.onSave) props.onSave();
    props.setVisible(false);
  };

  const getModalWidth = () => {
    return width > 500 ? 500 : width - 20;
  };

  return (
    <Modal
      visible={props.visible}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => props.setVisible(false)}
    >
      <Layout style={[styles.layout, { width: getModalWidth(), maxHeight: height - 100 }]}>
        <View style={styles.buttonGroup}>
          <Button appearance="ghost" status="danger" onPress={onCancel}>
            Cancel
          </Button>
          <Button appearance="ghost" onPress={onSave}>
            Save
          </Button>
        </View>
        {props.children}
      </Layout>
    </Modal>
  );
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: 'gray',
    margin: 10,
    width: '100%',
  },
  layout: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
