// Input for Employee
import React from 'react';
import { List, ListItem, StyleService, useStyleSheet, Icon, useTheme } from '@ui-kitten/components';

import InputBox from './InputBox';
import InputModal from './InputModal';
import { getLocation, getLocations } from '../services/Locations';

// PROPS
// LocationId: string (default: null)
//   - id of location to be selected
// onPress: function (required)
//  - function to set the selected employees

export default function InputLocation(props) {
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const [location, setLocation] = React.useState({ $id: '' });
  const [locations, setLocations] = React.useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);

  React.useEffect(() => {
    if (props.locationId) {
      getLocation(props.locationId).then((res) => {
        setLocation(res);
      });
    }
  }, [props.locationId]);

  const onPress = () => {
    getLocations().then((l) => {
      setLocations(l.documents);
    });
    setModalVisible(true);
  };

  const renderRadio = (item) => {
    if (item.$id === location.$id) {
      return <Icon color="" name="checkmark-circle-outline" fill={theme['color-primary-default']} />;
    }
    return <Icon color="" name="radio-button-off-outline" />;
  };

  const renderItem = ({ item }) => (
    <ListItem
      title={item.name}
      style={
        item.$id === location.$id
          ? { borderWidth: 1, borderColor: theme['color-primary-default'] }
          : {}
      }
      accessoryRight={renderRadio(item)}
      onPress={() => setLocation(item)}
    />
  );

  const renderLocationPicker = () => {
    return (
      <InputModal onSave={onSave} visible={modalVisible} setVisible={setModalVisible}>
        <List style={styles.listContainer} data={locations} renderItem={renderItem} />
      </InputModal>
    );
  };

  const onSave = () => {
    props.onPress(location);
    setModalVisible(false);
  };

  return (
    <>
      <InputBox
        title="Location"
        content={location.name ? location.name : 'Select a  Location'}
        icon="map-outline"
        color={theme['color-warning-default']}
        onPress={props.onPress ? onPress : null}
      />
      {renderLocationPicker()}
    </>
  );
}

const themedStyles = StyleService.create({
  listContainer: {
    width: '100%',
  },
});
