import React from 'react';
import { Layout, StyleService, Text, useStyleSheet, List } from '@ui-kitten/components';
import * as FileSystem from 'expo-file-system';

import { LOG_FILE } from '../../constants/constants';
import TopNav from '../../components/TopNav';
import { LOG } from '../../utils/logger';

export default function Logs() {
  const styles = useStyleSheet(themedStyles);
  const [fileData, setFileData] = React.useState();

  const readFile = () => {
    const path = `${FileSystem.cacheDirectory}${LOG_FILE}`;
    FileSystem.readAsStringAsync(path)
      .then((res) => {
        const lines = res.split('\n');
        setFileData(lines);
      })
      .catch((err) => {
        LOG.error(err);
      });
  };

  React.useEffect(() => {
    LOG.info('Logs screen loaded');
    LOG.debug('Logs screen loaded');
    readFile();
  }, []);

  const renderItem = ({ item, index }) => <Text key={index}>{item}</Text>;

  const cleanLog = () => {
    const path = `${FileSystem.cacheDirectory}${LOG_FILE}`;
    FileSystem.writeAsStringAsync(path, '').catch((err) => {
      LOG.error(err);
    });
    setFileData([]);
  };

  return (
    <Layout style={styles.container}>
      <TopNav
        title="Logs"
        leftAction={'back'}
        rightAction={{ icon: 'trash-outline', action: cleanLog }}
      />
      <List style={styles.listContainer} data={fileData} renderItem={renderItem} />
    </Layout>
  );
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scrollView: {
    padding: 10,
    flex: 1,
  },
});
