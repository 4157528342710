import React, { useState } from 'react';
import { StyleSheet, Platform } from 'react-native';
import { Layout, Text, Input, Button } from '@ui-kitten/components';

import TopNav from '../../components/TopNav';
import { createUser } from '../../services/Users';

export default function AddUser({ navigation }) {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [nickname, setNickname] = useState();

  const [error, setError] = useState();

  const onSuccess = () => {
    navigation.goBack();
  };

  const onFail = (e) => {
    var msg = e.message;
    setError(msg);
  };

  const addUser = () => {
    var user = { name, email, nickname };
    createUser(user, onSuccess, onFail);
  };

  return (
    <Layout style={styles.container}>
      <TopNav title="Add User" leftAction={'back'} />
      <Layout style={styles.innerContainer}>
        <Input
          style={styles.input}
          autoCapitalize="words"
          placeholder="Full Name"
          value={name}
          onChangeText={setName}
        />
        <Input
          style={styles.input}
          autoCapitalize="words"
          placeholder="Nick name"
          value={nickname}
          onChangeText={setNickname}
        />
        <Input
          style={styles.input}
          autoCapitalize="none"
          placeholder="Email"
          value={email}
          onChangeText={setEmail}
        />

        {error && <Text status="danger">{error}</Text>}

        <Button style={styles.button} onPress={addUser}>
          Save
        </Button>
      </Layout>
    </Layout>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  innerContainer: {
    padding: 10,
  },
  input: {
    paddingBottom: 10,
    ...Platform.select({
      web: { width: 500, maxWidth: '90%' },
    }),
  },
  button: {
    marginTop: 20,
    ...Platform.select({
      web: { width: 500, maxWidth: '90%' },
      default: { width: '100%' },
    }),
  },
});
