import React from 'react';
import { Icon, Text, Divider, TopNavigation, TopNavigationAction } from '@ui-kitten/components';
import { View, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';

// example usage:
// <TopNav
//  title="Add Item"
//  leftAction={'back'}
//  // it can be single or array of actions
//  rightAction={{ icon: 'save-outline', action: handleAdd }}
//  rightAction={[{ icon: 'save-outline', action: handleAdd }]}
//  />

export default function TopNav(props) {
  const navigation = useNavigation();

  const NavIcon = (props) =>
    Platform.OS === 'web' ? (
      <Icon {...props} onClick={props.onPress} name={props.name} />
    ) : (
      <Icon {...props} onPress={props.onPress} name={props.name} color="" />
    );

  const NavText = (props) =>
    Platform.OS === 'web' ? (
      <Text {...props} onClick={props.onPress}>
        {props.name}
      </Text>
    ) : (
      <Text {...props} onPress={props.onPress}>
        {props.name}
      </Text>
    );

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('Home');
    }
  };

  const LeftAction = () => {
    if (props.leftAction === 'back') {
      var backIcon = Platform.OS === 'ios' ? 'arrow-ios-back' : 'arrow-back';
      return <TopNavigationAction icon={<NavIcon name={backIcon} onPress={goBack} />} />;
    }
    if (props.leftAction === 'menu') {
      return (
        <TopNavigationAction
          icon={<NavIcon name="menu" onPress={() => navigation.openDrawer()} />}
        />
      );
    }
  };

  // this component can render another component or object that contain action and icon
  const RightAction = () => {
    if (props.rightAction) {
      // if valid react component
      if (React.isValidElement(props.rightAction)) {
        return props.rightAction;
      } else {
        // if array of action
        if (Array.isArray(props.rightAction)) {
          return props.rightAction.map((action, index) => renderRightAction(action, index));
        } else {
          // if single action
          return renderRightAction(props.rightAction);
        }
      }
    }
    return null;
  };

  const renderRightAction = (rightAction, index) => {
    if (rightAction.icon) {
      return (
        <TopNavigationAction
          key={index}
          icon={<NavIcon name={rightAction.icon} onPress={rightAction.action} />}
        />
      );
    } else {
      return <NavText key={index} name={rightAction.name} onPress={rightAction.action} />;
    }
  };

  return (
    <View>
      <TopNavigation
        alignment="center"
        title={props.title}
        accessoryLeft={LeftAction}
        accessoryRight={RightAction}
      />
      <Divider />
    </View>
  );
}
