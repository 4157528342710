import React from 'react';
import {
  useStyleSheet,
  StyleService,
  RangeCalendar,
  Calendar,
  useTheme,
  NativeDateService
} from '@ui-kitten/components';

import InputBox from './InputBox';
import InputModal from './InputModal';

// props
// date: Date
//  The date to display
export default function InputDate(props) {
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [date, setDate] = React.useState();
  const [range, setRange] = React.useState({ startDate: new Date() });

  // local date service
  const localeDateService = new NativeDateService('en', { startDayOfWeek: 1 });

  React.useEffect(() => {
    if (props.range) {
      setRange({ startDate: props.date ? new Date(props.date) : new Date() });
    } else {
      setDate(props.date ? new Date(props.date) : new Date());
    }
  }, [props.date]);

  const getMaxDate = () => {
    const curr = new Date();
    const max = curr.setFullYear(curr.getFullYear() + 1);
    return new Date(max);
  };

  const onPress = () => {
    setModalVisible(true);
  };

  const onSave = () => {
    if (props.range) {
      props.onPress(range);
    } else {
      props.onPress(date);
    }
  };

  const renderDatePicker = () => {
    return (
      <InputModal onSave={onSave} visible={modalVisible} setVisible={setModalVisible}>
        {props.range ? (
          <RangeCalendar
            dateService={localeDateService}
            style={styles.calendar}
            max={getMaxDate()}
            range={range}
            onSelect={(nextRange) => setRange(nextRange)}
          />
        ) : (
          <Calendar
            style={styles.calendar}
            date={date}
            onSelect={(nextDate) => setDate(nextDate)}
          />
        )}
      </InputModal>
    );
  };

  const renderDateText = () => {
    // render the date text
    if (props.range) {
      if (range.startDate && range.endDate) {
        return `${range.startDate.toLocaleDateString()} - ${range.endDate.toLocaleDateString()}`;
      } else if (range.startDate) {
        return `${range.startDate.toLocaleDateString()}`;
      } else {
        return 'Select Date';
      }
    } else {
      return date ? date.toLocaleDateString() : 'Select Date';
    }
  };

  return (
    <>
      <InputBox
        title="Date"
        content={renderDateText()}
        icon="calendar-outline"
        color={theme['color-success-default']}
        onPress={props.onPress ? onPress : null}
      />
      {renderDatePicker()}
    </>
  );
}

const themedStyles = StyleService.create({
  calendar: {
    width: '100%',
    borderWidth: 0,
    marginBottom: 10,
  },
});
