import React from 'react';
import { Platform, ImageBackground, StyleSheet, Pressable, View } from 'react-native';
import { Layout, Text, Input, Button, Icon } from '@ui-kitten/components';

import { login } from '../services/Auth';

const AlertIcon = (props) => <Icon {...props} name="alert-circle-outline" color="" />;

export default function Login({ navigation }) {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState();
  const [secureTextEntry, setSecureTextEntry] = React.useState(true);
  const image = require('../assets/images/green_goddess.png');

  const handleLogin = () => {
    login(navigation, email, password, setErrorMessage);
    setEmail('');
    setPassword('');
  };

  const handleForgot = () => {
    navigation.navigate('Forgot');
  };

  const toggleSecureEntry = () => {
    setSecureTextEntry(!secureTextEntry);
  };

  const renderIcon = (props) => (
    <Pressable onPress={toggleSecureEntry}>
      <Icon {...props} color="" name={secureTextEntry ? 'eye-off' : 'eye'} />
    </Pressable>
  );

  const renderCaption = () => {
    if (password.length > 0 && password.length < 8) {
      return (
        <View style={styles.captionContainer}>
          {AlertIcon(styles.captionIcon)}
          <Text style={styles.captionText}>Should contain at least 8 symbols</Text>
        </View>
      );
    }
  };
  return (
    <Layout style={styles.container} level="1">
      <ImageBackground source={image} resizeMode="contain" style={styles.imageBg}>
        <Input
          style={styles.input}
          autoCapitalize="none"
          placeholder="Email"
          value={email}
          onChangeText={setEmail}
        />
        <Input
          style={styles.input}
          value={password}
          placeholder="Password"
          caption={renderCaption}
          accessoryRight={renderIcon}
          secureTextEntry={secureTextEntry}
          onChangeText={setPassword}
        />
        {errorMessage && (
          <Text style={styles.captionText} status="danger">
            {errorMessage}
          </Text>
        )}
        <Button onPress={handleLogin} style={styles.button}>
          Login
        </Button>
        <Text style={styles.forgot} onPress={handleForgot}>
          forgot password?
        </Text>
      </ImageBackground>
    </Layout>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  imageBg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  input: {
    paddingBottom: 10,
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100%',
    ...Platform.select({ web: { maxWidth: 500 } }),
  },
  button: {
    marginTop: 20,
    width: '100%',
    ...Platform.select({ web: { maxWidth: 500 } }),
  },
  captionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  captionIcon: {
    width: 10,
    height: 10,
    marginright: 5,
  },
  captionText: {
    fontSize: 12,
    fontWeight: '400',
  },
  forgot: {
    marginTop: 20,
  },
});
