import React from 'react';
import { StyleSheet } from 'react-native';
import { Layout } from '@ui-kitten/components';
import LottieView from 'lottie-react-native';

// import { LOG } from '../utils/logger';
import { getLoggedInUser } from '../services/Auth';
import { APPWRITE_SERVER } from '../constants/constants';

export default function Splash({ navigation }) {
  const animation = React.useRef(null);

  React.useEffect(() => {
    setTimeout(() => {}, 1000);
    getLoggedInUser().then((value) => {
      if (value && value.appwriteServer === APPWRITE_SERVER) {
        navigation.replace('Main');
      }
      else {
        navigation.replace('Login');
      }
    });
  }, []);
  return (
    <Layout style={styles.container}>
      <LottieView
        autoPlay
        ref={animation}
        style={styles.egg}
        source={require('../assets/bounce-egg.json')}
      />
    </Layout>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  egg: {
    width: 350,
    height: 350,
  },
});
