import React from 'react';
import { Divider, Text, useTheme } from '@ui-kitten/components';
import { useColorScheme } from 'react-native';
import { TimePicker as SimpleTimePicker } from 'react-native-simple-time-picker';

import InputBox from './InputBox';
import InputModal from './InputModal';
import { formatTime, millisToTimePicker, timePickerToMillis } from '../utils/time';

export default function InputTime(props) {
  const theme = useTheme();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [startTime, setStartTime] = React.useState();
  const [endTime, setEndTime] = React.useState();
  const colorScheme = useColorScheme();
  const textColor = colorScheme === 'dark' ? 'white' : 'black';

  const onPress = () => {
    setStartTime(millisToTimePicker(props.startTime));
    setEndTime(millisToTimePicker(props.endTime));
    setModalVisible(true);
  };

  const onSave = () => {
    props.onPress(timePickerToMillis(startTime), timePickerToMillis(endTime));
    setModalVisible(false);
  };

  const renderTimePicker = () => {
    return (
      <InputModal onSave={onSave} visible={modalVisible} setVisible={setModalVisible}>
        <Text category="h6" status="primary">
          Start Time
        </Text>
        <SimpleTimePicker
          textColor={textColor}
          value={startTime}
          onChange={setStartTime}
          minutesInterval={30}
          isAmpm
          zeroPadding
        />
        <Divider />
        <Text category="h6" status="primary">
          End Time
        </Text>
        <SimpleTimePicker
          textColor={textColor}
          value={endTime}
          onChange={setEndTime}
          minutesInterval={30}
          isAmpm
          zeroPadding
        />
      </InputModal>
    );
  };

  return (
    <>
      <InputBox
        title="Time"
        content={formatTime(props.startTime) + ' - ' + formatTime(props.endTime)}
        icon="clock-outline"
        onPress={props.onPress ? onPress : null}
        color={theme['color-danger-default']}
      />
      {renderTimePicker()}
    </>
  );
}
