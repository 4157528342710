import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import ListUsers from '../screens/users/List';
import AddUser from '../screens/users/Add';
import ViewUser from '../screens/users/View';

const Nav = createNativeStackNavigator();

export default function UserNav() {
  return (
    <Nav.Navigator screenOptions={{ headerShown: false }} initialRouteName="Users">
      <Nav.Screen name="Users" component={ListUsers} />
      <Nav.Screen name="AddUser" component={AddUser} />
      <Nav.Screen name="ViewUser" component={ViewUser} />
    </Nav.Navigator>
  );
}
