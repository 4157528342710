// week picker component

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';

import CustomIcon from './CustomIcon';

export default function WeekPicker(props) {
  const [dates, setDates] = React.useState([]);

  const getWeek = () => {
    let curr = new Date(props.currentDate);
    // set dates range for this week
    let week = [];
    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first));
      week.push(day);
    }
    setDates(week);
  };

  React.useEffect(() => {
    getWeek();
  }, [props.currentDate]);

  const onPressLeft = () => {
    props.onPressLeft();
    getWeek();
  };
  const onPressRight = () => {
    props.onPressRight();
    getWeek();
  };

  return (
    <View style={styles.container}>
      <CustomIcon onPress={onPressLeft} style={styles.icon} name="arrow-left" />
      {dates.map((date, index) => (
        <Text key={index} style={styles.text}>
          {date.getDate()}
        </Text>
      ))}
      <CustomIcon onPress={onPressRight} style={styles.icon} name="arrow-right" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 14,
  },
  icon: {
    width: 20,
    height: 20,
    paddingHorizontal: 10,
  },
});
