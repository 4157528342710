import { Functions } from 'appwrite';

import client from '../utils/appwrite';

const functions = new Functions(client);

// Send Text
// {
//    "message": "Message to send",
//    "to": "+12155551234"
// }
const sendText = (data) => {
  return functions.createExecution('sendText', JSON.stringify(data));
};

export { sendText };
