import React from 'react';
import UserAvatar from 'react-native-user-avatar';

import { stringToHexColor, invertHexColor } from '../utils/color';

// props
// userId - user id
// name - user name
export default function Avatar(props) {
  const userColor = stringToHexColor(props.userId);
  const textColor = invertHexColor(userColor);
  return <UserAvatar bgColor={userColor} textColor={textColor} name={props.name} size={40} />;
}
